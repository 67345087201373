nav.navbar_c {
    /* background: linear-gradient(to bottom,#623111,#62311199); */
    min-height: 60px;
    padding: 0;
    border-bottom: 1px solid;
    padding-bottom: .1rem;
    padding-top: .1rem;
    justify-content: space-between;
}

div.logout_c {
    margin-right: .75rem;
    cursor: pointer;
}
div.topbar_right.topbar_custom, img.topbar_right.topbar_custom {
    margin-left: 0;
    margin-right: 1vw;
    height: min(65px, 5rem);
    width: min(65px, 5rem);
    box-shadow: 0px 0px 60px 1px whitesmoke;
}
div.topbar_right.topbar_custom.avatar-right, img.topbar_right.topbar_custom.avatar-right {
    margin-left: auto;
}
.topbar_custom.name_label {
    color: white;
    /* position: absolute; */
    align-self: flex-end;
    /* right: 6rem; */
    /* width: min(65px, 5rem); */
    text-align: center;
}
a.brand_c {
    padding-bottom: .1rem;
}

div.full-width.navbar-nav {
    flex-direction: row;
    align-items: center;
}
div.full-width.navbar-nav .dropdown-menu {
    position: absolute;
}
.super-user-tab-left {
    left: 60%;
}
.tab-left {
    left: 50%;
}
.nav-item.dropdown {
    margin-right: auto;
    margin-left: 4vw;
    border: solid 1px grey;
    border-radius: .3rem;

}
.dropdown.nav-item .dropdown-toggle.nav-link {
    padding: 0 .2rem;

}
.nav-item.dropdown:hover, .nav-item.dropdown.clicked {
    border: solid 2px black;
    margin-left: calc(4vw - 1px);
    background-color: whitesmoke
}
/* .read-only-card {
    border: solid gray .1px;
    border-radius: .3rem;
    padding: .1rem .3rem;
} */

@media only screen and (max-width: 991px) {
    div.full-width.navbar-nav.super-user {
        border-top: .1px solid grey;
        padding-top: .1rem;
    }
}
@media only screen and (max-width: 650px) {
    .tab-left {
        left: 62.5%;
    }
}