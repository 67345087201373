#root, html, body {
  min-width: 500px;
  min-height: 100vh;
}
.card.card_custom {
  width: 18rem;
  /* height: 25rem; */
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  flex-direction: unset;
  border-color: #62311199;
  box-shadow: 5px 5px 8px 2px #62311199;
  border-width: 1px;
}


.card.card_custom.center {
  text-align: center;
}
.flexContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* margin-top: 10vh; */
  justify-content: center;
  align-items: center;
  background: whitesmoke;
}

.rel_cont {
  position: relative;
}

.boomLogo {
  border-color: #62311199;
  border-width: 1px;
  box-shadow: 5px 5px 8px 2px #62311199;
  width: 18rem;
  border-radius: .325rem
}

.tb2rem {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tb_sm_rem {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.r1rem {
  margin-right: 1rem;
}

div.alert_c {
  margin-top: .5rem;
  margin-bottom: 0rem;
}

.temp_center {
  width: fit-content;
  margin: auto;
  font-size: 32pt;
  padding: 6rem;
}

div.card.card_custom.page_card {
  width: 100%;
  height: 100%;
  box-shadow: none; 
  align-items: initial;
  border: none;
  border-radius: 1rem;
  margin-bottom: 0;
  background: white;
}

div.flexContainer.screenContainer {
  display: block;
  margin-top: 0;
  height:100%;
}


nav.sideNav_c button[role="button"] span {
  background-color: black;
}

nav.sideNav_c button[role="button"] span:nth-of-type(2) {
  height: 1.9px;
  /* margin-top: 2px; */
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}

