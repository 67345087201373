.middle {
    height: 5rem;
    width: 5rem;
    color: white;
    background: lightblue;
    font: unset;
    font-size: 3.3rem;
    text-align: center;
    border-radius: 3rem;
    cursor: pointer;
    align-content: center;
}

.flex {
    display: Grid;
    text-align: center;
    margin-left: 5.25rem;
    margin: auto;
}