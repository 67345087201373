.label {
    font-size: 16px;
    color: grey;
    font-weight: 400;
    /* height: 0px; */
    padding: 0;
    transition:  opacity 0s;
    opacity: 0;
    overflow: hidden;
}

.mb-point-25-rem {
    margin-bottom: .25rem
}

.show {
    transition: opacity 2s;
    height: auto;
    padding:.4rem 0 .125rem .2rem;
    opacity: 1;
    font-weight: bold;
}
.deleteBtn{
    float: right;
    margin-top: .5rem;
    box-shadow: none !important;
}

.passBtn{
    margin-left: 10px;
    box-shadow: none !important;
}