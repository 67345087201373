.middle {
  height: 5rem;
  width: 5rem;
  color: white;
  background: lightblue;
  font: unset;
  font-size: 3.3rem;
  text-align: center;
  border-radius: 3rem;
  cursor: pointer;
}

.flex {
  display: Grid;
  text-align: center;
  margin-left: 5.25rem;
}

input.hidden {
  position: absolute;
  top: 0.5rem;
  left: 43%;
  opacity: 0;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
  /* border: lightgrey 1px solid;
    border-radius: .25rem; */
  font-size: 18px;
  /* color: grey; */
  transition: font-weight 0s, padding 0s;
  /* margin-bottom: .25rem; */
}

.label_show {
  padding-left: 0.2rem;
  font-weight: bold;
  transition: font-weight 0.5s, padding 0.25s;
}

.onTop {
  font-size: 26px;
  font-weight: 300;
  position: absolute;
  top: -9%;
  left: 17.01rem;
}
.closeButton {
  position: absolute;
  top: 4%;
  left: 43%;
}
.hide {
  visibility: hidden;
}
