.form-wrapper {
    background: url("https://raw.githubusercontent.com/locatejp/assets/master/Uluru.jpg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: calc(100vh - 69.15px);
    padding: 5%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    overflow: auto;
}

.form-body {
    width: 50%;
    min-width: 300px;
    margin: auto;
    border: 2px solid ghostwhite;
    border-radius: 1rem;
    padding: 10px;
    background-color: white;
    opacity: 0.9;
}

.form-heading {
    text-align: center;
    font-size: 1.50rem;
}
div.form-group.mb-point-25-rem, label.form-label.mb-point-25-rem{
    margin-bottom: .25rem;
}