/* nav.sideNav_c::before {
    background-color: #fda65c;
    content: '';
    opacity: .5
} */

nav.sideNav_c {
    /* background-image:  linear-gradient(to right, #fda65c00, #fda65c), url('https://www.newstatesman.com/sites/default/files/styles/lead_image/public/Longreads_2019/12/personal_story.jpg?itok=D8iYo9yy&c=835d1ac0608e9a2bbb5cbe3dfda62aef'); */
    /* background-color: #ffa726; */
    background-color: #cccccc;
    /* background-color: white; */
    
    background-image:  linear-gradient(to right, #cccccc, #ffffff);
    -webkit-transition: background-image 0.2s;
    -moz-transition: background-image 0.2s;
    -o-transition: background-image 0.2s;
    -ms-transition: background-image 0.2s;
    transition: background-image 0.2s;
    border-right:  #cccccc solid .1px;
    /* box-shadow: 5px 1px 4px 1px grey */
    /* color: white; */
    /* border-top-right-radius: .25rem; */
    /* border-bottom-right-radius: .25rem; */
}
nav.sideNav_c.collapsed {
    background-image:  linear-gradient(to right, #cccccc, #ffffff);
    /* border-right: solid grey 1px; */
    /* border-color: unset; */
    /* box-shadow: unset; */
}
div.navText_c.custom {
    font-size: 14pt;
}
.full-width {
    width: 100%
}
