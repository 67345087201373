.main {
    width: calc(100% - 64px);
    position: relative;
    left: 64px;
    -webkit-transition: width  0.2s, left 0.2s;
    -moz-transition: width  0.2s, left 0.2s;
    -o-transition: width  0.2s, left 0.2s;
    -ms-transition: width  0.2s, left 0.2s;
    transition: width  0.2s, left 0.2s;
    /* min-height: 100%; */
    height: calc(100vh - 69.15px);
    overflow-x: auto;
    overflow-y: visible;
}

.expanded {
    width: calc(100% - 240px);
    left: 240px;
}

.view {
    position: relative; 
    height: calc(100vh - 69.15px);
}
.header-margin {
    margin-top: 71px;
    transition: 1s margin-top;

}
.header-expand {
    margin-top: 142px;
}


